import clsx from 'clsx'
import classes from './LandingBestSupport.module.scss'
import SupportSummer from './best-support-summer.svg'
import SupportAutumn from './best-support-autumn.svg'
import SupportWinter from './best-support-winter.svg'

export const LandingBestSupport = () => {
  return (
    <section className={clsx('flex-column', classes.section)}>
      <div className={classes.content}>
        <div className={clsx('flex-column', classes.heading)}>
          <h2 className={clsx('page__title', classes.title)}>
            Best support and services on the market.
            <br />
            Period.
          </h2>
          <p className={clsx('page__subtitle', classes.subtitle)}>
            Our world-class solutions team is just one Slack message away. We
            help you understand complex APIs, build robust integration logic,
            and keep your customer integrations running smoothly
          </p>
        </div>
        <div className={classes.awards}>
          <img
            src={SupportSummer.src}
            width={97}
            height={126}
            alt='best support summer 2024 award'
            loading='lazy'
          />
          <img
            src={SupportAutumn.src}
            width={97}
            height={126}
            alt='best support autumn 2024 award'
            loading='lazy'
          />
          <img
            src={SupportWinter.src}
            width={97}
            height={126}
            alt='best support winter 2024 award'
            loading='lazy'
          />
        </div>
      </div>
    </section>
  )
}
