import { Link } from 'routes/components/Shared'
import { LINKS } from 'routes/constants'
import { Carousel, useCarousel } from 'routes/components/Shared/Carousel'
import { companiesData } from './companiesData'
import { LandingCompaniesCard } from './LandingCompaniesCard'
import clsx from 'clsx'
import classes from './LandingCompanies.module.scss'

export const LandingCompanies = () => {
  return (
    <section className={clsx('flex-column', classes.section)}>
      <h2 className={clsx('page__title', classes.title)}>
        The best SaaS companies are building with us.
      </h2>
      <p className={clsx('page__subtitle', classes.subtitle)}>
        From startups to established enterprises, <br /> from one-click
        integrations to automation platforms and Al agents.
      </p>

      <Slider />

      <div className={clsx('page__actions', classes.actions)}>
        {/* <Link
          className={clsx('page__button', classes.button)}
          type='secondary'
          href={LINKS.SOLUTIONS}
        >
          Explore Solutions
        </Link> */}
        <Link
          className={'page__button'}
          type='secondary'
          href={LINKS.CASE_STUDIES}
        >
          Read Case Studies
        </Link>
      </div>
    </section>
  )
}

function Slider() {
  const { slidesToRender, carouselSettings } = useCarousel(companiesData)

  return (
    <Carousel className={classes.slider} {...carouselSettings}>
      {slidesToRender.map((card, index) => {
        return (
          <div className={classes.slide} key={index}>
            <LandingCompaniesCard card={card} />
          </div>
        )
      })}
    </Carousel>
  )
}
