import { Reviews } from 'routes/components/Features'
import clsx from 'clsx'
import classes from './LandingReviews.module.scss'

export const LandingReviews = () => {
  return (
    <section className={clsx(classes.section, 'flex-column')}>
      <h2 className={classes.title}>Don't just take our word for it</h2>
      <p className={classes.subtitle}>
        Hear from some of our amazing customers
      </p>
      <Reviews />
    </section>
  )
}
