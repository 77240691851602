import { CONNECTOR_DOCS_LINK } from 'routes/constants'
import { Link } from 'routes/components/Shared'
import clsx from 'clsx'
import classes from './LandingInteraction.module.scss'
import mobile1x from './interaction-mobile.png'
import mobile2x from './interaction-mobile@2x.png'
import desktop1x from './interaction.png'
import desktop2x from './interaction@2x.png'
import React from 'react'

export const LandingInteraction = () => {
  return (
    <section className={clsx('flex-column page__section', classes.section)}>
      <div className={clsx('flex-column', classes.heading)}>
        <h2 className={clsx('page__title', classes.title)}>
          Interact with every app through a standard API
        </h2>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          Our open connector framework lets you access data and metadata in
          every external API in a consistent way.
        </p>
      </div>
      <div className={classes.visual}>
        <picture>
          <source
            media='(min-width: 768px)'
            srcSet={`${desktop1x.src} 1x, ${desktop2x.src} 2x`}
          />
          <source
            media='(max-width: 767px)'
            srcSet={`${mobile1x.src} 1x, ${mobile2x.src} 2x`}
          />
          <img src={desktop1x.src} alt={''} />
        </picture>
      </div>
      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={clsx('page__button', classes.link)}
          type='secondary'
          href={CONNECTOR_DOCS_LINK}
        >
          Read the docs
        </Link>
      </div>
    </section>
  )
}
